import React from "react"
import Navbar from "./components/navbar"
import { Helmet } from "react-helmet"

const Success = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mostaza | Franquicias</title>
        <link rel="canonical" href="https://franquicias.mostazaweb.com.ar/" />
        <script
          type="text/javascript"
          defer
          async
          id="lightning_bolt"
          src="//cdn-akamai.mookie1.com/LB/LightningBolt.js"
        ></script>
      </Helmet>

      <Navbar />
      <script type="text/javascript" defer async>
        {(() => {
          // typeof window !== "undefined" && window.ga('event', 'conversion', { 'send_to': 'AW-972714142/1cWWCICTy7gBEJ7h6c8D', 'event_callback': 'https://franquicias.mostazaweb.com.ar/success' });
          typeof window !== "undefined" &&
            window.gtag("event", "conversion", {
              send_to: "AW-972714142/1cWWCICTy7gBEJ7h6c8D",
              event_callback: "https://franquicias.mostazaweb.com.ar/success",
            })
          console.log("Sent Conversion Tag")
        })()}
      </script>
      <div className="main-container">
        <section
          style={{ backgroundColor: "#BC2130", height: "100vh" }}
          id="container1"
        >
          <h1 className="main-title">Gracias por enviar tu mensaje</h1>
          <p className="main-subtitle">En breve estaremos contactandonos</p>
        </section>
      </div>
    </div>
  )
}

export default Success
